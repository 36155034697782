import React, {useEffect, useMemo, useState, useCallback} from 'react';
import styled from 'styled-components';
import {isStaff, isReviewer, isVIP} from '../../Domain/RoleValidator';
import LogisticBlock from '../../Blocks/LogisticBlock';
import ReturnBlock from '../../Blocks/ReturnBlock';
import InvoiceBlock from '../../Blocks/InvoiceBlock';
import ExtraOrderDialog from '../../Dialogs/ExtraOrderDialog';
import ExtraOrderBlock from '../../Blocks/ExtraOrderBlock';
import ExtraOrderDataBlock from './ExtraOrderDataBlock';
import {Alert, message, Spin} from 'antd';
import * as Ant from 'antd';
import fetchProductSpecByOrder from '../../Utils/fetchProductSpecByOrder';
import {ORDER_TYPE, isOrderDisabled} from '../../Utils/OrderUtil';
import VoidDialog from '../../Dialogs/VoidOrderDialog';
import OrderHistorySection from './OrderHistorySection';
import {WorkDocument, WorkFormPdfDownload} from '../../Exports/WorkFormPdf';
import {
  WorkInnerDocument,
  WorkInnerFormPdfDownload,
} from '../../Exports/WorkInnerFormPdf';
import {
  QuotationDocument,
  QuotationFormPdfDownload,
} from '../../Exports/QuotationFormPdf';
import {TestDocument} from '../../Exports/TestPdf';
import {SalesDocument, SalesOrderPdfDownload} from '../../Exports/SalesFormPdf';
import PdfPreviewDialog from '../../Dialogs/PdfPreviewDialog';
import BackButton from '../../Components/BackButton';
import {useOutlet} from 'reconnect.js';
import PeriodBlock from '../../Blocks/PeriodBlock';
import Button from '../../Widgets/Button';
import Row from '../../Widgets/Row';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import OrderBlock from './OrderBlock';
import OrderDialog, {
  UISTATE as ORDER_DIALOG_TYPE,
} from '../../Dialogs/OrderDialog';
import CalculationBlock from './CalculationBlock';
import MemberBlock from './MemberBlock';
import OrderItem from './OrderItem';
import CreditOrderDataBlock from './CreditOrderDataBlock';
import OrderItemShortcutDialog from '../../Dialogs/OrderItemShortcutDialog';
import * as FileUtil from '../../Utils/FileUtil';
const appConfig = require('../../data.json');
const qs = require('query-string');

const PDFDownloadComp = (props) => {
  const style = {
    padding: '6px 15px',
    borderRadius: 4,
    border: 'solid 1px #1890ff',
    display: 'inline-block',
  };
  switch (props.type) {
    case 'inner-work':
      return <WorkInnerFormPdfDownload {...props} style={style} />;
    case 'inner-private-work':
      return (
        <WorkInnerFormPdfDownload {...props} style={style} isPrivate={true} />
      );
    case 'work':
      return <WorkFormPdfDownload {...props} style={style} />;
    case 'quotation':
      return <QuotationFormPdfDownload {...props} style={style} />;
    case 'sales':
      return <SalesOrderPdfDownload {...props} style={style} />;

    default:
      return null;
  }

  return null;
};

export default function OrderDetail(props) {
  const {id} = qs.parse(props.location.search);
  const [orderType, setOrderType] = useState(ORDER_TYPE.default);
  const [order, setOrder] = useState(null);
  const [buyer, setBuyer] = useState(null);
  const [orderItems, steOrderItems] = useState(null);
  const [openOrderDialog, setOpenOrderDialog] = useState(false);
  const [openExtraOrderDialog, setOpenExtraOrderDialog] = useState(false);
  const [openVoidDialog, setOpenVoidDialog] = useState(false);
  const [openShortcutDialog, setOpenShortcutDialog] = useState(false);
  const [productSpec, setProductSpec] = useState(null);
  const [pdfType, setPdfType] = useState(null);
  const [extraLoading, setExtraLoading] = useState(false);
  const [loading, setLoading] = useOutlet('loading');
  const [profile] = useOutlet('user');
  const [actions] = useOutlet('actions');
  const [suppliers, setSuppliers] = useState([]);
  const [reviewers, setReviewers] = useState([]);
  const [logistics, setLogistics] = useState([]);
  const isStaffOrReviewer = useMemo(
    () => profile && (isStaff(profile) || isReviewer(profile)),
    [profile],
  );
  const disabled = useMemo(() => isOrderDisabled(order), [order]);
  const cart = useMemo(() => {
    try {
      return JSON.parse(order.cart);
    } catch (err) {}
  }, [order]);

  const getOrder = useCallback(async () => {
    setLoading(true);
    try {
      setOrder(null);
      let resp = await actions.getOrder(id);
      setOrder(resp);

      setOrderType(ORDER_TYPE[resp.order_type]);
    } catch (err) {
      message.warning('無法取得訂單資訊');
      console.warn(err);
    }
    setLoading(false);
  }, [id]);

  const getOrderItems = useCallback(async () => {
    try {
      let resp = await actions.getOrderItems(id);

      if (Array.isArray(resp)) {
        resp.sort((a, b) => a.item_index - b.item_index);
      }

      steOrderItems(resp);
    } catch (err) {
      message.warning('無法取得稿件資訊');
      console.warn(err);
    }
  }, [id]);

  const getMember = useCallback(async () => {
    try {
      if (isStaffOrReviewer && order && order.buyer) {
        let resp = await actions.getMember(order.buyer.id);
        setBuyer(resp);
      }
    } catch (err) {
      console.warn(err);
      message.warning('無法取得會員資訊');
    }
  }, [order]);

  const getReviewers = useCallback(async () => {
    if (profile && profile.staff_type === 'reviewer') {
      setReviewers([profile]);
    } else {
      const resp = await actions.getReviewers();
      setReviewers(resp);
    }
  }, [profile, actions]);

  const getSuppliers = useCallback(async () => {
    let resp = await actions.getSuppliers();
    setSuppliers(resp);
  }, [actions]);

  useEffect(() => {
    getReviewers();
    getSuppliers();
  }, [getReviewers, getSuppliers]);

  useEffect(() => {
    (async () => {
      await Promise.all([getOrder(), getOrderItems()]);
    })();
  }, [getOrder, getOrderItems]);

  useEffect(() => {
    (async () => {
      if (order) {
        setExtraLoading(true);
        const [productSpec] = await Promise.all([
          fetchProductSpecByOrder(order),
          getMember(),
        ]);
        setProductSpec(productSpec);
        setExtraLoading(false);
      }
    })();
  }, [order, getMember]);

  useEffect(() => {
    (async () => {
      try {
        const {results} = await actions.getLogistics({
          order: id,
          ordering: '-created',
        });
        setLogistics(results);
      } catch (ex) {
        console.warn(ex);
      }
    })();
  }, [id]);

  // for image source clean origin util
  // const getCleanOriginImageUrl = useCallback(async (thumbnail) => {
  //     try {

  //     // const thumbnail = "https://cdn.lixiangprint.com.tw/+-1639739425.png";
  //     console.log("thumbnail", thumbnail);
  //     const resp = await fetch(thumbnail);
  //     const contentType = resp.headers.get('content-type');
  //     const blob = await resp.blob();;
  //     const objectUrl = URL.createObjectURL(blob, { type: contentType });
  //     return objectUrl ;
  //   } catch(err) {
  //     console.warn("getCleanOriginImageUrl error", err);
  //   }
  // }, [])

  // useEffect(() => {
  //   if(orderItems) {
  //     (async () => {
  //       const resp = await getCleanOriginImageUrl(orderItems[0].thumbnail_url);
  //       console.log("clean url", resp);
  //       setObjectUrl(resp);
  //     })()
  //   }
  // }, [orderItems, getCleanOriginImageUrl])

  if (extraLoading || !order || !orderItems) {
    return (
      <Wrapper>
        <BackButton pathname="/orders/" />
        <div style={{display: 'flex', alignItems: 'center', marginTop: 20}}>
          <Ant.Spin
            spinning={true}
            indicator={
              <LoadingOutlined style={{fontSize: 22, color: '#000'}} />
            }
            style={{marginRight: 20}}
          />
          <h2 style={{margin: 0}}>載入中...</h2>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {/* {order.voided && (
        <Alert
          type="error"
          message={`此訂單已被取消`}
          description={order.void_reason ? `原因:${order.void_reason}` : ''}
          banner
          style={{marginBottom: 10}}
        />
      )} */}
      <Row style={{marginBottom: 20}}>
        <BackButton pathname="/orders/" />

        <div style={{flex: 1}} />
        <Button
          type="primary"
          style={{marginLeft: 8}}
          color={appConfig.colors.error}
          disabled={order.voided || disabled}
          onClick={() => setOpenVoidDialog(true)}>
          取消訂單
        </Button>

        <Button
          type="primary"
          disabled={
            disabled ||
            orderType === ORDER_TYPE.credit ||
            orderType === ORDER_TYPE.extra
          }
          color="#438de1"
          style={{marginLeft: 8}}
          onClick={() => setOpenExtraOrderDialog(true)}>
          新增補收款
        </Button>

        <Button
          type="primary"
          disabled={
            disabled ||
            orderType === ORDER_TYPE.credit ||
            orderType === ORDER_TYPE.extra
          }
          color="#7cc261"
          style={{marginLeft: 8}}
          onClick={() => setPdfType('sales')}>
          銷貨單.pdf
        </Button>
        <Button
          type="primary"
          disabled={
            disabled ||
            orderType === ORDER_TYPE.credit ||
            orderType === ORDER_TYPE.extra
          }
          color="#7cc261"
          style={{marginLeft: 8}}
          onClick={() => setPdfType('quotation')}>
          估價單.pdf
        </Button>
        <Button
          type="primary"
          disabled={
            disabled ||
            orderType === ORDER_TYPE.credit ||
            orderType === ORDER_TYPE.extra
          }
          color="#e19f42"
          style={{marginLeft: 8}}
          onClick={() => setPdfType('inner-work')}>
          內部工作單.pdf
        </Button>
        <Button
          type="primary"
          disabled={
            disabled ||
            orderType === ORDER_TYPE.credit ||
            orderType === ORDER_TYPE.extra
          }
          color="#e19f42"
          style={{marginLeft: 8}}
          onClick={() => setPdfType('inner-private-work')}>
          內部工作單(隱藏金額).pdf
        </Button>
        <Button
          type="primary"
          disabled={
            disabled ||
            orderType === ORDER_TYPE.credit ||
            orderType === ORDER_TYPE.extra
          }
          color="#e19f42"
          style={{marginLeft: 8}}
          onClick={() => setPdfType('work')}>
          廠商工作單.pdf
        </Button>

        <Button
          shape="circle"
          style={{marginLeft: 8, border: 0}}
          onClick={() => {
            getOrder();
            getOrderItems();
          }}
          icon={<Refresh color={appConfig.colors.main} size={26} />}
        />
      </Row>

      <Ant.Row gutter={[20, 20]}>
        <Ant.Col span={12}>
          <OrderBlock order={order} setOpenOrderDialog={setOpenOrderDialog} />
        </Ant.Col>
        <Ant.Col span={6}>
          <CalculationBlock
            order={order}
            setOpenOrderDialog={setOpenOrderDialog}
          />
        </Ant.Col>
        <Ant.Col span={6}>
          <MemberBlock order={order} buyer={buyer} />
        </Ant.Col>

        <Ant.Col span={12}>
          {orderType === ORDER_TYPE.extra ? (
            <ExtraOrderDataBlock order={order} />
          ) : orderType === ORDER_TYPE.credit ? (
            <CreditOrderDataBlock order={order} />
          ) : (
            <LogisticBlock order={order} onUpdate={getOrder} />
            // '此訂單不支援物流'
          )}
        </Ant.Col>
        <Ant.Col span={12}>
          <InvoiceBlock order={order} onUpdate={getOrder} />
        </Ant.Col>

        {order.extra_order && order.extra_order.length > 0 && (
          <Ant.Col span={24}>
            <ExtraOrderBlock
              order={order}
              orderItems={orderItems}
              records={order.extra_order}
            />
          </Ant.Col>
        )}

        {orderType !== ORDER_TYPE.credit &&
          orderType !== ORDER_TYPE.extra &&
          orderItems.length > 0 &&
          productSpec &&
          cart && (
            <React.Fragment>
              <Ant.Col span={24}>
                <div
                  style={{
                    display: 'flex',
                    fontSize: 20,
                  }}>
                  <div>訂單商品資訊</div>
                  <div style={{flex: 1}} />
                  <div>共 {orderItems.length} 筆商品</div>
                  <Button
                    type="primary"
                    style={{marginLeft: 10}}
                    onClick={() => setOpenShortcutDialog(true)}>
                    批次編輯
                  </Button>
                  <Button
                    type="primary"
                    style={{marginLeft: 10}}
                    disabled={orderItems.length === 0}
                    onClick={async () => {
                      actions.setLoading(true);
                      await FileUtil.downloadZip(
                        orderItems
                          .filter((orderItem) => orderItem.attachment_uploaded)
                          .map((orderItem) => orderItem.latest_attachment.file),
                        {zipName: `稿件${order?.display_id}.zip`},
                      );
                      actions.setLoading(false);
                    }}>
                    批次下載稿件
                  </Button>
                </div>
              </Ant.Col>
              {orderItems.map((orderItem, idx) => (
                <Ant.Col span={24} key={idx}>
                  <OrderItem
                    orderItem={orderItem}
                    cartItem={cart.items[orderItem.item_index]}
                    order={order}
                    productSpec={productSpec}
                    onUpdate={() => {
                      getOrder();
                      getOrderItems();
                    }}
                    suppliers={suppliers}
                    reviewers={reviewers}
                  />
                </Ant.Col>
              ))}
            </React.Fragment>
          )}

        <Ant.Col span={12}>
          <OrderHistorySection order={order} />
        </Ant.Col>
        {order &&
          !['credit', 'extra', 'monthly', 'period'].includes(
            order.order_type,
          ) &&
          order.payment_type !== 'monthly' && (
            <Ant.Col span={12}>
              <ReturnBlock order={order} />
            </Ant.Col>
          )}

        {order?.order_type === 'period' && (
          <Ant.Col span={12}>
            <PeriodBlock order={order} />
          </Ant.Col>
        )}
      </Ant.Row>

      <ExtraOrderDialog
        visible={openExtraOrderDialog}
        baseOrderId={order.id}
        onUpdate={() => {
          getOrder();
          getOrderItems();
        }}
        onClose={() => setOpenExtraOrderDialog(false)}
      />

      <VoidDialog
        show={openVoidDialog}
        order={order}
        closeDialog={() => setOpenVoidDialog(false)}
        refresh={getOrder}
      />

      <PdfPreviewDialog
        visible={!!pdfType}
        loading={!order || !productSpec}
        onClose={() => setPdfType(null)}
        PDFDownloadComp={
          <PDFDownloadComp
            type={pdfType}
            order={order}
            orderItems={orderItems}
            productSpec={productSpec}
            logistics={logistics}
          />
        }>
        {pdfType === 'inner-work' || pdfType === 'inner-private-work' ? (
          <WorkInnerDocument
            order={order}
            orderItems={orderItems}
            productSpec={productSpec}
            isPrivate={pdfType === 'inner-private-work'}
            logistics={logistics}
          />
        ) : pdfType === 'work' ? (
          <WorkDocument
            order={order}
            orderItems={orderItems}
            productSpec={productSpec}
          />
        ) : pdfType === 'quotation' ? (
          <QuotationDocument
            order={order}
            orderItems={orderItems}
            productSpec={productSpec}
          />
        ) : pdfType === 'sales' ? (
          <SalesDocument
            order={order}
            orderItems={orderItems}
            productSpec={productSpec}
          />
        ) : pdfType === 'test' ? (
          <TestDocument />
        ) : null}
      </PdfPreviewDialog>

      <OrderDialog
        visible={openOrderDialog}
        order={order}
        onUpdate={getOrder}
        closeDialog={() => setOpenOrderDialog(false)}
      />

      <OrderItemShortcutDialog
        order={order}
        visible={openShortcutDialog}
        onClose={() => setOpenShortcutDialog(false)}
        onUpdate={() => {
          getOrder();
          getOrderItems();
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
`;
